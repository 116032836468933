﻿import { Rest_API } from "../../system/services/rest_api";

import { age_cat } from "./../objects/v1_3/web_engine/age_cat";

import { filter } from "./../objects/v1_3/web_engine/filter";
import { filters } from "./../objects/v1_3/web_engine/filters";

import { local_filter } from "../objects/front_end/search/local_filter";

import { country } from "../objects/v1_3/web_engine/country";

import { currency } from "../objects/v1_2/Currency/currency";
import { stringifyQuery } from "vue-router";
import { filtertag } from "../objects/v1_3/web_engine/filtertag";

export class Web_Engine {

    Rest : Rest_API = new Rest_API();

    public async Get_Passengers(): Promise<age_cat> {
        return this.Rest.Get_Data<age_cat>(import.meta.env.VITE_APP_URL + "/api/v1.2/" + import.meta.env.VITE_APP_ID + "/web_engine/age_category").then(response => { return response.json(); });
    }
    public async Download_Filter_Info(): Promise<filters> {
        return this.Rest.Get_Data<filters>(import.meta.env.VITE_APP_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/web_engine/filter").then(response => { return response.json(); });
    }
    public async Get_Filters(): Promise<local_filter[]> {
        let filters: filters = await this.Download_Filter_Info();
        let Return_Filters: local_filter[] = [];
        let index = 0;

        filters.filters.forEach(filter => {
            Return_Filters.push({
                name: filter.name,
                isOpen: false,
                selectedTag: new filtertag(),
                tags: filter.tags
            })
        });

        return Return_Filters;
    }
    public async Get_Countries(): Promise<country> {
        return await this.Rest.Get_Data<filter[]>(import.meta.env.VITE_APP_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/web_engine/country").then(response => { return response.json(); });
    }
    public async Get_Currency(): Promise<currency> {
        return await this.Rest.Get_Data<currency>(import.meta.env.VITE_APP_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/web_engine/country/" + import.meta.env.VITE_DEFAULT_COUNTRY).then(response => { return response.json(); });
    }
    public async Get_User_Currency(): Promise<currency> {
        let returnCurrency = await this.Rest.Get_Data<currency>(import.meta.env.VITE_IP_URL + "/api/v1.3/web_engine/currency").then(response => { return response.json(); });
        if(returnCurrency.currency_info == null) {
            returnCurrency = await this.Get_Currency();
        }
        return returnCurrency
    }
    public async Initialise_Currency(store: any, currency_override: string) {

        let user_currency : currency; 
        
        if (currency_override != '') 
        {
            user_currency = this.Get_Currency_Override(currency_override);
        } 
        else
        {
            user_currency = await this.Get_User_Currency();
        }

        store.commit('Initialise_Currency', { Currency_Details: user_currency });

        if (store.getters.get_Agent_Login?.currencies?.length > 0) {
            let Currency_Info = store.getters.get_Currency;
            Currency_Info.currency_info = store.getters.get_Agent_Login.currencies[0];
            store.commit('Initialise_Currency', { Currency_Details: Currency_Info });
        }
    }
    public Get_Currency_Override(currency_override : string) : currency {
        const currency_aud : currency = {country:'Override - Australia', dialing_code:'61', iso_country_code:'AU', currency_info:{currency:'AUD', currency_mask:'$#,##0.00 ', iso_currency_code:'AUD' }};
        const currency_cad : currency = {country:'Override - Canada', dialing_code:'1', iso_country_code:'CA', currency_info:{currency:'CAD', currency_mask:'$#,##0.00 ', iso_currency_code:'CAD' }};
        const currency_eur : currency = {country:'Override - Germany', dialing_code:'49', iso_country_code:'DE', currency_info:{currency:'EUR', currency_mask:'€#,##0.00 ', iso_currency_code:'EUR' }};
        const currency_gbp : currency = {country:'Override - United Kingdom', dialing_code:'44', iso_country_code:'GB', currency_info:{currency:'GBP', currency_mask:'£#,##0.00 ', iso_currency_code:'GBP' }};
        const currency_nzd : currency = {country:'Override - New Zealand', dialing_code:'64', iso_country_code:'NZ', currency_info:{currency:'NZD', currency_mask:'$#,##0.00 ', iso_currency_code:'NZD' }};
        const currency_usd : currency = {country:'Override - United States of America', dialing_code:'1', iso_country_code:'US', currency_info:{currency:'USD', currency_mask:'$#,##0.00 ', iso_currency_code:'USD' }};
        const currency_zar : currency = {country:'Override - South Africa', dialing_code:'27', iso_country_code:'ZA', currency_info:{currency:'ZAR', currency_mask:'R#,##0.00 ', iso_currency_code:'ZAR' }};
    
        switch(currency_override) {
            case "aud": {
                return currency_aud;
                break;
            }
            case "cad": {
                return currency_cad;
                break;
            }
            case "eur": {
                return currency_eur;
                break;
            }
            case "gbp": {
                return currency_gbp;
                break;
            }
            case "nzd": {
                return currency_nzd;
                break;
            }
            case "usd": {
                return currency_usd;
                break;
            }
            case "zar": {
                return currency_zar;
                break;
            }
            default: {
                return currency_gbp;
                break;
            }
        }
    }
    
}