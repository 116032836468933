﻿import { defineComponent } from 'vue';

import { Booking } from "../../../services/booking";

const Book = new Booking();

export default defineComponent({
    name: "trip_summary_discount",
    props: {
    },
    data() {
        return {
            Discount_Set: false,
            Discount_Found: false,
            Discount_Not_Found: false,
            discount_loading: false
        }
    },
    computed: {
        promo_code: {
            get() {
                return this.$store.getters.get_Basket?.trips[0]?.promo_code;
            },
            async set(newValue: string) {
                this.Discount_Set = newValue != "";

                if (newValue != this.$store.getters.get_Basket.trips[0].promo_code) {
                    this.Discount_Found = false;
                    this.Discount_Not_Found = false;
                }

                if (this.$store.getters.get_Basket.trips[0].promo_code + "" != "" && newValue == "") {
                    this.$store.getters.get_Basket.trips[0].promo_code = "";

                    if (this.$store.getters.get_Booking?.trips[0].booking_identifier != null) {
                        await Book.Update_Booking_Promo(this.$store.getters.get_Booking.group_booking_id, this.$store.getters.get_Booking, "");
                        this.$store.commit("Setup_Booking", { Booking_Details: await Book.Get_Book(this.$store.getters.get_Booking.group_booking_id) });
                    } else {
                        this.$store.commit("Setup_Booking", { Booking_Details: await Book.Quote_Booking(this.$store) });
                    }

                } else {
                    this.$store.getters.get_Basket.trips[0].promo_code = newValue;
                }
            }
        }
    },
    methods: {
        get_Booking() {
            return this.$store.getters.get_Booking;
        },
        get_Basket() {
            return this.$store.getters.get_Basket;
        },
        async Apply_Discount() {
            this.discount_loading = true;
            if (this.promo_code + "" != "") {
                this.Discount_Set = false;

                console.log(this.$store.getters.get_Booking);

                if (this.$store.getters.get_Booking?.trips[0].booking_identifier != null) {
                    await Book.Update_Booking_Promo(this.$store.getters.get_Booking.group_booking_id, this.$store.getters.get_Booking, this.$store.getters.get_Basket.trips[0].promo_code);
                    this.$store.commit("Setup_Booking", { Booking_Details: await Book.Get_Book(this.$store.getters.get_Booking.group_booking_id) });
                } else {
                    this.$store.commit("Setup_Booking", { Booking_Details: await Book.Quote_Booking(this.$store) });
                }

                this.Discount_Found = this.$store.getters.get_Booking?.trips[0]?.passengers[0].discount > 0;
                this.Discount_Not_Found = !this.Discount_Found;
            }
            this.discount_loading = false;
        },
        promo_text(): string {
            if (this.Discount_Found == true) {
                return Book.get_Discount_Description(this.$store.getters.get_Booking, this.$store);
            }

            if (this.Discount_Not_Found == true) {
                return "Invalid or expired coupon code.";
            }

            return "";
        }
    },
    mounted() {
    }
})