﻿<template>
    <agent_options v-on:onFilterChange="onFilterChanges" v-if="showAgentPopup()" ></agent_options>
    <div class="filters">
        <div class="tour-search z-40">

            <!--@@ Mobile Trip Filter Overlay @@-->
            <div class="w-6/12 block md:hidden">
                <button type="button" class="select-button" @click="filterMenu = false" :class="{'active': filterMenu}">
                    <span class="ml-8 font-bold text-sm text-heading">Filter Trips</span>
                    <span class="icon inline left-4">
                        <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="sliders-h" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-sliders-h fa-w-16 fa-2x"><path fill="currentColor" d="M504 384H192v-40c0-13.3-10.7-24-24-24h-48c-13.3 0-24 10.7-24 24v40H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h88v40c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24v-40h312c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm-344 64h-32v-96h32v96zM504 96H256V56c0-13.3-10.7-24-24-24h-48c-13.3 0-24 10.7-24 24v40H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h152v40c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24v-40h248c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm-280 64h-32V64h32v96zm280 80h-88v-40c0-13.3-10.7-24-24-24h-48c-13.3 0-24 10.7-24 24v40H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h312v40c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24v-40h88c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm-120 64h-32v-96h32v96z"></path></svg>
                    </span>
                </button>
            </div>

            <!--@@ Trip Filter @@-->
            <div class="filterMenu overlay" :class="{'hidden': filterMenu}">
                <filter_list v-on:onFilterChange="onFilterChanges" ref="filter_list"></filter_list>

                <div class="w-full md:w-2/12">
                    <button type="button" class="calendar-button" @click="Open_Calendar()" :class="{'active': isCalendarOpen}">
                        <span class="icon inline">
                            <svg class="inline -mt-1 mr-1 svg-inline--fa fa-calendar-alt fa-w-14 fa-2x" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="calendar-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M400 64h-48V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H128V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h352c8.8 0 16 7.2 16 16v48H32v-48c0-8.8 7.2-16 16-16zm352 384H48c-8.8 0-16-7.2-16-16V192h384v272c0 8.8-7.2 16-16 16zM148 320h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 96h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm192 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12z"></path></svg>
                        </span>
                        Travel Dates
                    </button>

                    <div class="slideout" :class="{'active': isCalendarOpen, 'hidden': !isCalendarOpen}"
                         x-transition:enter="transition ease-out duration-500 -mr-64"
                         x-transition:enter-start=""
                         x-transition:enter-end="transform -translate-x-64"
                         x-transition:leave="transform ease-out duration-75"
                         x-transition:leave-start=""
                         x-transition:leave-end="transform translate-x-64">
                        <div class="slideout-inner">
                            <button class="close no-bg" @click="isCalendarOpen = false"></button>

                            <calendar @tourSelected="Tour_Selected" v-on:onSearchChange="onSearchChanges" ref="calendar"></calendar>
                        </div>

                        <div class="slideout-bg" @click="isCalendarOpen = true"></div>

                    </div>

                </div>

                <div class="overlay-close">
                    <button class="mr-1 button button-pink full" @click="filterMenu = true">Apply</button>
                    <button class="ml-1 button button-pink full" @click="filterMenu = true">Close</button>
                </div>
                <div class="w-7/12 md:w-4/12 lg:w-4/12" :class="{'hidden' :!display_search_box()}">
                    <!--working search bar-->
                    <div class="ml-1 form-group">
                        <!--name="hidden prevents Chrome and firefox from auto fillinf with Email."-->
                        <input type="text" name="hidden" id="search" class="search" v-on:keyup.enter="Search_Products(tour_search)" placeholder="Search for tours" v-model="tour_search">
                        <div class="input-icon apply" @click="filterMenu = true">
                            <button v-if="tour_search != ''" class="close no-bg" @click="clear_search()"></button>
                            <button @click="Search_Products(tour_search)">
                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-search fa-w-16 fa-2x"><path fill="currentColor" d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z" class=""></path></svg>
                            </button>
                        </div>
                    </div>
                    <!--disabled search bar
                    <div class="ml-1 form-group" v-if="!DepartureAdded && GetBrand() == 'HEX'">
                        <input :disabled="true" type="text" name="search" id="search" class="opacity-60 search" v-on:keyup.enter="Search_Products(tour_search)" placeholder="Add a Departure location to search" v-model="tour_search">
                        <div class="opacity-10 input-icon apply" @click="filterMenu = true">
                            <button :disabled="true" @click="Search_Products(tour_search)">
                                <svg :disabled="true" aria-hidden="true" focusable="false" data-prefix="far" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-search fa-w-16 fa-2x"><path fill="currentColor" d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z" class=""></path></svg>
                            </button>
                        </div>
                    </div>-->
                </div>
            </div>
            <router-link to="/cart" tag="button">
                <button @mouseenter="showCart = true" @mouseleave="showCart = false" type="button" class="calendar-button mt-5 m-3 py-3">
                    <svg class="inline -mt-1 mr-1 w-5 svg-inline--fa fa-shopping-cart fa-w-18 fa-2x" aria-hidden="true" focusable="false" data-prefix="far" data-icon="shopping-cart" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M551.991 64H144.28l-8.726-44.608C133.35 8.128 123.478 0 112 0H12C5.373 0 0 5.373 0 12v24c0 6.627 5.373 12 12 12h80.24l69.594 355.701C150.796 415.201 144 430.802 144 448c0 35.346 28.654 64 64 64s64-28.654 64-64a63.681 63.681 0 0 0-8.583-32h145.167a63.681 63.681 0 0 0-8.583 32c0 35.346 28.654 64 64 64 35.346 0 64-28.654 64-64 0-18.136-7.556-34.496-19.676-46.142l1.035-4.757c3.254-14.96-8.142-29.101-23.452-29.101H203.76l-9.39-48h312.405c11.29 0 21.054-7.869 23.452-18.902l45.216-208C578.695 78.139 567.299 64 551.991 64zM208 472c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm256 0c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm23.438-200H184.98l-31.31-160h368.548l-34.78 160z" class=""></path></svg>
                    Cart ({{cart_count()}})
                </button>
                <div @mouseenter="showCart = true" @mouseleave="showCart = false" v-if="showCart" class="cursor-pointer mt-5 m-3 py-3 w-60 align-right select-dropdown">
                    <svg class="inline -mt-1 ml-5 mr-1 w-5 svg-inline--fa fa-shopping-cart fa-w-18 fa-2x" aria-hidden="true" focusable="false" data-prefix="far" data-icon="shopping-cart" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M551.991 64H144.28l-8.726-44.608C133.35 8.128 123.478 0 112 0H12C5.373 0 0 5.373 0 12v24c0 6.627 5.373 12 12 12h80.24l69.594 355.701C150.796 415.201 144 430.802 144 448c0 35.346 28.654 64 64 64s64-28.654 64-64a63.681 63.681 0 0 0-8.583-32h145.167a63.681 63.681 0 0 0-8.583 32c0 35.346 28.654 64 64 64 35.346 0 64-28.654 64-64 0-18.136-7.556-34.496-19.676-46.142l1.035-4.757c3.254-14.96-8.142-29.101-23.452-29.101H203.76l-9.39-48h312.405c11.29 0 21.054-7.869 23.452-18.902l45.216-208C578.695 78.139 567.299 64 551.991 64zM208 472c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm256 0c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm23.438-200H184.98l-31.31-160h368.548l-34.78 160z" class=""></path></svg>
                   Cart ({{cart_count()}})
                    <ul v-if="$store.getters.get_Basket.trips.length > 0" v-for="trip in $store.getters.get_Basket.trips">
                        <li>{{ get_Tour_Details(trip.tour_id).name}}</li>
                    </ul>
                    <ul v-else>
                        <li>Cart is empty</li>
                    </ul>
                </div>
            </router-link>

                <!-- Trip Filter -->

        </div>

        <div class="tour-result mx-4 md:mx-8">
            <p>{{search_result()}} <a @click="Clear_Filter()" class="hover:no-underline rounded-full my-2 my-2 mx-0 md:mx-2 px-3 py-1 text-sm button button-bluelight">Clear Search Filter</a></p>
        </div>

    </div>

</template>

<script lang="ts" src="./searchfilter.ts"></script>