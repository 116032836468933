﻿import { booking_group } from "../objects/v1_2/Booking/Booking_Group";
import { add_on } from "../objects/v1_2/Booking/Add_On";
import { trip } from "../objects/v1_2/Booking/Trip";
import { room } from "../objects/v1_2/Booking/Room";
import { passenger } from "../objects/v1_2/Booking/Passenger";
import { booking_information } from "../objects/v1_2/Booking/Booking_Information";
import { room_passenger } from "../objects/v1_2/Booking/Room_Passenger";

import { room_passengers_type } from "../objects/front_end/room/room_passengers_type";
import { trip_passenger } from '../objects/v1_2/Booking/Trip_Passenger';

import { pickup_point } from "../objects/v1_3/product/pickup/pickup_point";

export class Basket {
    public Initialise_Basket(Quote_Basket: booking_group, Currency: string, Trip_Index: number, Tour_ID: string) {
        Quote_Basket.booking_information = new booking_information();
        Quote_Basket.booking_information.iso_currency_code = Currency;

        if (Trip_Index == 0) {
            Quote_Basket.trips = [];
            Quote_Basket.passengers = [];
        }

        let Tour: trip = new trip();
        Tour.tour_id = Tour_ID;
        Tour.promo_code = "";
        Tour.add_ons = [];
        Tour.rooms = [];

        Quote_Basket.trips.push(Tour);

        return Quote_Basket;
    }
    public Initialise_Basket_Departure(Quote_Basket: booking_group, Trip_Index: number, Departure: string) {
        if (Quote_Basket == null) return null;
        if (Quote_Basket.trips == null) return Quote_Basket;
        if (Quote_Basket.trips[Trip_Index] == null) return Quote_Basket;

        Quote_Basket.trips[Trip_Index].departure_date = Departure;

        return Quote_Basket;
    }
    public Change_Basket_Primary_Tour(Quote_Basket: booking_group, Trip_Index: number, Tour_ID: string) {
        Quote_Basket.trips[Trip_Index].tour_id = Tour_ID;

        return Quote_Basket;
    }
    public Remove_Product(Quote_Basket: booking_group, Tour_ID: string) {
        let Trips: trip[] = [];

        Quote_Basket.trips.forEach(function (Trip) {
            if (Trip.tour_id != Tour_ID) {
                let Add_Ons: add_on[] = [];
                Trip.add_ons.forEach(function (Add_On) {
                    if (Add_On.tour_id != Tour_ID) {
                        Add_Ons.push(Add_On);
                    }
                });

                Trip.add_ons = Add_Ons;
                Trips.push(Trip);
            }
        });

        Quote_Basket.trips = Trips;

        return Quote_Basket;
    }
    public Add_Add_On(Quote_Basket: booking_group, Parent_Tour_ID: string, Tour_ID: string, Passengers: trip_passenger[]) {
        Quote_Basket.trips.forEach(function (Trip) {
            if (Trip.tour_id == Parent_Tour_ID) {
                let Add_On: add_on = new add_on();
                Add_On.tour_id = Tour_ID;
                Add_On.passengers = Passengers;

                Trip.add_ons.push(Add_On);
            }
        });

        return Quote_Basket;
    }
    public Add_Add_On_Passenger_Change(Quote_Basket: booking_group, Parent_Tour_ID: string, Tour_ID: string, Index: number) {
        Quote_Basket.trips.forEach(function (Trip) {
            if (Trip.tour_id == Parent_Tour_ID) {
                Trip.add_ons.forEach(function (Trip_Add_On) {
                    if (Trip_Add_On.tour_id == Tour_ID) {
                        let trip_pax: trip_passenger = new trip_passenger();
                        trip_pax.index = Index;

                        let index_location: number = Trip_Add_On.passengers.findIndex(f => f.index == trip_pax.index, 0);
                        if (index_location == -1) {
                            Trip_Add_On.passengers.push(trip_pax);
                        } else {
                            Trip_Add_On.passengers.splice(index_location, 1);
                        }
                    }
                })
            }
        });

        return Quote_Basket;
    }
    public Initialise_Passenger(Quote_Basket: booking_group) {
        Quote_Basket.passengers = [];

        return Quote_Basket;
    }
    public Passenger_Add(Quote_Basket: booking_group, Age_Category: string) {
        let Passenger = new passenger();
        Passenger.age_category = Age_Category;
        Quote_Basket.passengers.push(Passenger);

        return Quote_Basket;
    }
    public Passenger_Remove(Quote_Basket: booking_group, Age_Category: string) {
        let Index: number = 0;
        let Item_Found: number = -1;

        for (let Passenger of Quote_Basket.passengers) {
            if (Passenger.age_category == Age_Category) {
                Item_Found = Index;                
                break;
            }
            Index++;
        }

        if (Item_Found >= 0) {
            Quote_Basket.passengers.splice(Item_Found, 1);
        }

        return Quote_Basket;
    }
    public Initialise_Booking_Room_Type(Quote_Basket: booking_group, Trip_Index: number, room_type: room_passengers_type): booking_group {
        Quote_Basket.trips[Trip_Index].add_ons = [];
        Quote_Basket.trips[Trip_Index].rooms = [];

        for (let Room of room_type.room_type_combinations) { 
            let Booked_Room = new room();
            Booked_Room.room_type = Room.name;

            Booked_Room.passengers = [];
            for (var room_link of Room.passenger_links) {
                let Room_Passenger: room_passenger = new room_passenger();
                Room_Passenger.index = room_link.index;

                Booked_Room.passengers.push(Room_Passenger);
            }

            Quote_Basket.trips[Trip_Index].rooms.push(Booked_Room);
        }

        return Quote_Basket;
    }
    public Initialise_Pickup_Point(Quote_Basket: booking_group, Trip_Index: number, pickup_point: pickup_point): booking_group {
        Quote_Basket.trips[Trip_Index].pickup_point = pickup_point.original_id;

        return Quote_Basket;
    }
}