﻿import { defineComponent } from 'vue';

import trip_summary from "../common/trip_summary.vue"
import trip_summary_discount from "../common/trip_summary_discount.vue"
import itinerary_breakdown from "../common/itinerary_breakdown.vue";

import { Booking } from "../../../services/booking";
import { Payment } from "../../../services/payment";

import { Currency_Service } from "../../../../system/services/currency";

const Book: Booking = new Booking();
const Pay: Payment = new Payment();
const Currency_Services: Currency_Service = new Currency_Service();

import { response } from "../../../objects/v1_3/payment/initialise/response";
import { response_method } from "../../../objects/v1_3/payment/initialise/response_method";

import { booking_search_group } from "../../../objects/v1_2/Booking_Details/booking_search_group";

export default defineComponent({
    name: "checkout_trip_summary",
    components: {
        trip_summary,
        trip_summary_discount,
        itinerary_breakdown
    },
    props: {
    },
    data() {
        return {
            checkout: "Check out now",
            full_payment: true,
            deposit: false,
            agreeCheckbox: false as Boolean,
            displayCompleteRequired: false as Boolean,
        }
    },
    methods: {
        async check_out() {
            if (Book.Valid_Overall_Passenger(this.$store.getters.get_Booking) == true) {
                this.$store.commit("Set_Payment_Full_Payment", { Payment: this.full_payment });
                this.$store.commit("Set_Payment_Deposit", { Payment: this.deposit });

                this.$router.push({ path: "/creditcard" });
            } else {
                window.alert("Some Passenger Details missing");
            }
        },
        enableContinueButton() {
            if(this.agreeCheckbox == true) {
                if(this.$store.getters.get_isValidPassengers == true){
                    this.displayCompleteRequired = false;
                    return true;
                }
                else
                {
                    this.displayCompleteRequired = true;
                }
            }

            return false;

        },
        No_Deposit(): boolean {
            return Book.get_total_outstanding(this.$store.getters.get_Booking) == Book.get_Total_Deposit(this.$store.getters.get_Booking);
        },
        get_total_outstanding(): string {
            return Currency_Services.Format_Currency(Book.get_total_outstanding(this.$store.getters.get_Booking), this.$store.getters.get_Currency);
        },
        get_Total_Deposit(): string {
            return Currency_Services.Format_Currency(Book.get_Total_Deposit(this.$store.getters.get_Booking), this.$store.getters.get_Currency);
        },
        get_Total_Selected(): string {
            if (this.full_payment == true) {
                return this.get_total_outstanding();
            }

            if (this.deposit == true) {
                return this.get_Total_Deposit();
            }

            return "";
        },
        get_Balance(): string {
            return Currency_Services.Format_Currency(Book.get_Balance(this.$store.getters.get_Booking), this.$store.getters.get_Currency);
        },
        full_payment_selected() {
            this.full_payment = true;
            this.deposit = false;
        },
        deposit_selected() {
            this.full_payment = false;
            this.deposit = true;
        },
        get_Currency(): string {
            return this.$store.getters.get_Currency?.currency_info?.iso_currency_code;
        },
        terms_policy(): string {
            return String(import.meta.env.VITE_FOOTER_POLICY_TERMS);
        },
        get_Contact_Text(): string {
            return String(import.meta.env.VITE_DEFAULT_CONTACT_TEXT);
        },
        isAgent_Login(): boolean {
            return this.$store.getters.get_Agent_Login?.valid_login == true;
        },
    },
    mounted() {
        if (this.$store.getters.get_Agent_Login?.valid_login == true) {
            this.agreeCheckbox = true;
        }
    }
})