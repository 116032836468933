﻿import { defineComponent, PropType } from 'vue';
import axios from 'axios';

import { passenger } from "../../../objects/v1_2/Booking/Passenger";

import { country } from "../../../objects/v1_3/web_engine/country";
import { country_nationality } from '../../../objects/v1_3/web_engine/country_nationality';

import { Booking } from "../../../services/booking";
import { Web_Engine } from "../../../services/web_engine";

const Book = new Booking();
const Web = new Web_Engine();

const capitalise = {
    updated: (el: any, binding: any) => {
        el.value = el.value.toLowerCase().replace(/^.|\s\S/g, function (a) { return a.toUpperCase(); });
    }
}


export default defineComponent({
    name: "passenger",
    props: {
        index: Number,
        passenger: Object as PropType<passenger>
    },
    directives: {
        capitalise
    },
    data() {
        return {
            isOpen: true,
            countries: [] as country_nationality[],
            saving_passenger: false as Boolean,
            has_saved_details: false as Boolean,
            confirm_email: null as string | null,
            date_of_birth: null as string | null,
            countryName: null as string | null | undefined,
            newsletterCheckbox: false as Boolean,
            DoBValid: true as Boolean,
            dialCodeLead: null as string | null,
            dialNumberLead: null as string | null,
            dialCodeEmerg: null as string | null,
            dialNumberEmerg: null as string | null
        }
    },
    methods: {
        async Update_Details(e: any) {
            this.setCountry();
            this.passenger.date_of_birth = this.date_of_birth;

            if(!this.isAgent_Login()){
                this.passenger.telephone_number = this.combineDialCode(this.dialCodeLead, this.dialNumberLead);
                this.passenger.emergency_number = this.combineDialCode(this.dialCodeEmerg, this.dialNumberEmerg);
            }

            if (this.Valid_Form(this.passenger ?? new passenger(), this.$store.getters.get_Booking.passengers) == true) {

                this.saving_passenger = true;

                if(this.newsletterCheckbox && !this.isAgent_Login()) {
                    this.postFloDesk();
                }

                await Book.Update_Booking_Passengers(this.$store.getters.get_Booking, this.$store.getters.get_Agent_Login, this.$store.getters.get_Currency).then(response => { return response.json(); });
                this.$store.commit("Setup_Booking", { Booking_Details: await Book.Get_Book(this.$store.getters.get_Booking.group_booking_id, this.$store.getters.get_Agent_Login) });
                this.saving_passenger = false;
                this.has_saved_details = true;

                this.isOpen = false;

                //we need to check here if all pax are valid and only then set this to true
                let allPaxValid : boolean = this.AllPassengers_Details_Collected();
                if (allPaxValid == true) {
                    this.$store.commit("Set_isValidPassengers", { isValidPassengers: true });
                }

                if (this.$store.getters.get_Booking.passengers.length <= (this.index ?? 0) + 1) {
                    this.$router.push({ hash: "#payment_checkout" });
                } else {
                    this.$router.push({ hash: "#passenger_" + this.$store.getters.get_Booking.passengers[(this.index ?? 0) + 1].passenger_identifier });
                }

                e.preventDefault();
            }
            else
            {
                this.$store.commit("Set_isValidPassengers", false);
            }
        },
        combineDialCode(dialCode, dialNumber): string {
            var fullNumber = "";

            if (dialNumber != undefined) {
                if ( Array.from(dialNumber)[0] == "0") {
                    dialNumber = dialNumber.substring(1);
                }
            }

            if(dialCode != null && dialNumber != null){
                var clearCode = dialCode.match(/\(([^)]+)\)/)[1];
                if(clearCode != null){
                    fullNumber = clearCode + dialNumber;
                }

            }

            return fullNumber;

        },
        isValidDoB(dateString: string | null | undefined): boolean {
            let returnVal = false;
            let dateDigits = dateString?.split('-');
            let day = parseInt(dateDigits?.[0] ?? "0");
            let month = parseInt(dateDigits?.[1] ?? "0");
            let year = parseInt(dateDigits?.[2] ?? "0");

            if(day > 0 && day < 32 && month > 0 && month < 13 && year > 1900 && year < new Date().getFullYear()+1){
                returnVal = true;
                this.DoBValid = true;
            }
            else {
                this.DoBValid = false;
            }

            

            return returnVal;

        },        
        Valid_Form(Passenger: passenger, Booking_Passengers: passenger[]): boolean {
            let Return_Value: boolean = true;

            /*
            if(this.isValidDoB(this.date_of_birth)){
                if(import.meta.env.VITE_DEFAULT_SET_DOB){
                    let dateDigits = this.date_of_birth?.split('-');
                    if(parseInt(dateDigits?.[2] ?? "0") > 1900 && parseInt(dateDigits?.[2] ?? "0") < new Date().getFullYear()-18){
                        this.date_of_birth = "01-01-" + dateDigits?.[2]
                    }
                    else{
                        this.date_of_birth = "01-01-1970";
                    }

                }
                else{
                    Return_Value = this.isValidDoB(this.date_of_birth);
                }
                
            }
            */


            // if(!this.isAgent_Login()){
            //     Return_Value = this.isValidDoB(this.date_of_birth);
            // } 

            Booking_Passengers.forEach((booking_passenger: passenger) => {

                booking_passenger.first_name = this.String_Char_limit(booking_passenger.first_name ?? "", 30);
                booking_passenger.family_name = this.String_Char_limit(booking_passenger.family_name ?? "", 30);
                booking_passenger.emergency_contact = this.String_Char_limit(booking_passenger.emergency_contact ?? "", 30);

                if (Passenger.passenger_identifier == booking_passenger.passenger_identifier) {

                    Return_Value = this.Check_Field(Return_Value, "first_name", booking_passenger.first_name);
                    Return_Value = this.Check_Field(Return_Value, "family_name", booking_passenger.family_name);
                    Return_Value = this.Check_Field(Return_Value, "email", booking_passenger.email);
                    Return_Value = this.Check_Field(Return_Value, "iso_nationality", booking_passenger.iso_nationality);
                    Return_Value = this.Check_Field(Return_Value, "date_of_birth", booking_passenger.date_of_birth);
                    Return_Value = this.Check_Field(Return_Value, "telephone_number", booking_passenger.telephone_number);
                    Return_Value = this.Check_Field(Return_Value, "emergency_contact", booking_passenger.emergency_contact);
                    Return_Value = this.Check_Field(Return_Value, "emergency_number", booking_passenger.emergency_number);
                    Return_Value = this.Check_Field(Return_Value, "gender", booking_passenger.gender);
                }
            });

            return Return_Value;
        },
        Check_Field(Return_Value: boolean, field_name: string, field_value: string | null): boolean {
            if (this.require_field(field_name) == true) {
                if (field_value == null || field_value.trim() + "" == "" || Return_Value == null) {
                    Return_Value = false;
                }
            }

            return Return_Value;
        },
        String_Char_limit(value: string, limit: number): string {
            if (value.length > limit) {
                value = value.substring(0, limit);
            }

            return value;
        },
        isAgent_Login(): boolean {
            return this.$store.getters.get_Agent_Login?.valid_login == true;
        },
        async Get_Country() {
            this.countries = (await Web.Get_Countries()).countries;
        },
        Passenger_Details_Collected(): boolean {
            let result : boolean = false;
            if (this.has_saved_details) {
                result = Book.Valid_Passenger_Complete(this.passenger?.passenger_identifier ?? "", this.$store.getters.get_Booking_Un_Edited);
            }
            return result;
        },
        AllPassengers_Details_Collected(): boolean {
            return Book.Valid_AllPassengers_Complete(this.$store.getters.get_Booking_Un_Edited);
        },
        hide_field(Field: string): boolean {
            let Field_Hide: boolean = true;
            this?.passenger?.required_fields.forEach(function (Input_Field: string) {
                if (Input_Field == Field) {
                    Field_Hide = false;
                }
            });

            this?.passenger?.optional_fields.forEach(function (Input_Field: string) {
                if (Input_Field == Field) {
                    Field_Hide = false;
                }
            });

            return Field_Hide;
        },
        require_field(Field: string): boolean {
            let Require_Field: boolean = false;
            this?.passenger?.required_fields.forEach(function (Input_Field: string) {
                if (Input_Field == Field) {
                    Require_Field = true;
                }
            });

            return Require_Field;
        },
        isMobile(): boolean{
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        formatDOB() {

            let dob = this.date_of_birth?.toString().replaceAll('-', '')

            switch (dob?.length) {
                case undefined:
                case 0:
                case 1:
                case 2:
                    break;
                case 3:
                    dob = dob?.substring(0, 2) + "-" + dob?.substring(2, 3);
                    break;
                case 4:
                    dob = dob?.substring(0, 2) + "-" + dob?.substring(2, 4);
                    break;
                case 5:
                case 6:
                case 7:
                case 8:
                    dob = dob?.substring(0, 2) + "-" + dob?.substring(2, 4) + "-" + dob?.substring(4, dob?.length);
                    break;
                default:
                    dob = dob?.substring(0, 2) + "-" + dob?.substring(2, 4) + "-" + dob?.substring(4,8);
                    break;

            }
            return dob;

        },
        setGender(gender: string) {
            this.passenger.gender = gender;
        },
        setCountry() {
            if (this.countryName == null) return;

            this.countries.forEach(country => {
                if(country.country.trim() == this.countryName.trim()){
                    if(country.iso_country_code != null){
                        this.passenger.iso_nationality = country.iso_country_code;
                    }
                }
            });
        },
        getFloDeskAPI(): string {
            return String(import.meta.env.VITE_FLODESK_API);
        },
        getFloDeskKEY(): string {
            return String(import.meta.env.VITE_FLODESK_KEY);
        },
        getFloDeskSegment(): string {
            return String(import.meta.env.VITE_FLODESK_SEGMENT);
        },
        getAppName(): string {
            return String(import.meta.env.VITE_GA_APP_NAME);
        },
        getIsDebug(): boolean {
            return Boolean(import.meta.env.VITE_DEFAULT_IS_DEBUG);
        },
        autofill() {
            this.passenger.first_name = "Debug";
            this.passenger.family_name = "Test";
            this.passenger.telephone_number = "0123456789";
            this.date_of_birth = "01-01-1970";
            this.passenger.email = "Test@TTC.com";
            this.confirm_email = "Test@TTC.com";
            this.countryName = "United Kingdom";
            this.passenger.emergency_contact = "Test";
            this.passenger.emergency_number = "0123456789";
            this.setGender('Male');
            this.Update_Details();


        },
        getDialingCodes(countries: country_nationality[]): country_nationality[] {
            var ValidCountries = [] as country_nationality[];

            countries.forEach(country => {
                if(country.dialing_code != null && country.country != null && country.dialing_code.toLowerCase() != "null" && country.country.toLowerCase() != "null"){
                    ValidCountries.push(country);
                }
            });

            return ValidCountries;
        },
        isLeadPax() : boolean {
            return this.index == 0;
        },
        setNullNames() {
            if(!this.isAgent_Login()) { // && this.isLeadPax()) {
                if (this.require_field("first_name")){
                    this.passenger.first_name = "";
                }
                if(!this.isAgent_Login() && this.require_field("family_name")){
                    this.passenger.family_name = "";
                }
            }
        },
        async postFloDesk() {

            axios.post(this.getFloDeskAPI(), {
                email: this.$store.getters.get_Booking.passengers[0].email,
                first_name: this.$store.getters.get_Booking.passengers[0].first_name,
                last_name: this.$store.getters.get_Booking.passengers[0].family_name,
            },
            {
                headers: {"User-Agent": this.getAppName(),"Authorization": "Basic " + this.getFloDeskKEY()}
            })
            .then((res) => {
                console.log(res.data)

                axios.post('https://api.flodesk.com/v1/subscribers/'+this.$store.getters.get_Booking.passengers[0].email+'/segments', {

                id_or_email: this.$store.getters.get_Booking.passengers[0].email,
                segment_ids: [this.getFloDeskSegment()]

                },
                {
                    headers: {"User-Agent": this.getAppName(),"Authorization": "Basic "+ this.getFloDeskKEY()}
                })
                .then((res) => {console.log(res.data)})
                .catch((error) => {console.error(error)})
            })
            .catch((error) => {console.error(error)})
        }

        
    },
    mounted() {
        this.setNullNames();
        this.Get_Country();
    }
})