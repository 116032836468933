﻿<template>
    <div class="cart-summary w-full md:w-5/12 lg:w-4/12">
        <div class="basket-summary border border-form rounded w-full p-8 md:p-4 lg:p-8 flex flex-col">

            <itinerary_breakdown></itinerary_breakdown>
            <hr />
            <trip_summary></trip_summary>
            <trip_summary_discount></trip_summary_discount>
            <hr />

            <div class="summary-options section" :hidden="No_Deposit()" id="payment_checkout">

                <h6 class="title text-base text-heading font-bold">Payment Options</h6>
                <fieldset class="option-group accommodation" x-data="radioGroup()">

                    <div x-ref="radiogroup">

                        <div class="item" :class="{ '': !full_payment, 'checked': full_payment}">
                            <div class="option-input">
                                <input id="settings-option-8" name="myradio5" type="radio" checked @click="full_payment_selected()">
                            </div>
                            <div class="option-content">
                                <label for="settings-option-8">
                                    <span class="room">
                                        {{get_total_outstanding()}} full amount
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div class="item" :class="{ '': !deposit, 'checked': deposit}">
                            <div class="option-input">
                                <input id="settings-option-9" name="myradio5" type="radio" @click="deposit_selected()">
                            </div>
                            <div class="option-content">
                                <label for="settings-option-9">
                                    <span class="room">
                                        {{get_Total_Deposit()}} deposit
                                    </span>
                                </label>
                            </div>
                        </div>

                    </div>
                </fieldset>

            </div>

            <div class="summary-total section">
                <div class="row">
                    <h6 class="title text-base text-heading font-bold">Total Price</h6>
                    <p id="totalPrice" class="balance text-black text-2xl text-action font-bold mb-0">{{get_Total_Selected()}} <span class="font-normal text-lg">{{get_Currency()}}</span> <small class="font-normal text-sm">includes all taxes.</small></p>
                    <p class="balance-remaning text-sm mt-2 mb-0" :hidden="full_payment">Balance of <span class="font-bold">{{get_Balance()}} {{get_Currency()}}</span></p>
                </div>
            </div>

            <hr />
            <div v-if="!isAgent_Login()">
                <p class="m-auto font-bold text-black pt-2">By booking with us you agree to our <a :href=terms_policy() target="_blank">terms and conditions</a></p>
                <div class="flex my-4">
                    <input v-model="agreeCheckbox" type="checkbox" class="focus:ring-action text-action border-grey-light rounded mr-5 mt-1 cursor-pointer">
                    <label>I have read and agree with the terms and conditions</label>
                </div>
            </div>

            <div class="summary-action">
                <div v-if="displayCompleteRequired" class="row">
                    <span class="text-xl text-status-warning" >Please complete all required passenger details to continue</span>
                </div>
                <div class="row">
                    <button v-if="enableContinueButton()" type="button" class="button button-pink-book full" @click="check_out()">{{checkout}}</button>
                    <button v-else type="button" class="border-grey-default bg-form border-blank button text-base text-action full" disabled>Agree to continue</button>
                </div>
                <div class="row">
                    <small class="block mt-4 text-grey-default">{{get_Contact_Text()}}</small>
                </div>
            </div>

            <div id="payment-gateway-component"></div>
        </div>
    </div>
</template>

<script lang="ts" src="./summary.ts"></script>